import React, { useState, useEffect, useRef } from "react"
import { Divider, Row, Col } from "antd"
import { useParams, useNavigate, useOutletContext } from "react-router-dom"

import { hasAccess } from "@components/Authorization"
import { W2, W5, DRAWER_WIDTH } from "@components"
import { runRequest, useAppContext } from "@components/AppContext"
import { optimizeMutation, useForm } from "@components/Form"
import { readOperation, updateOperation } from "@components/Store/BackstageStore/useCampaignsStore"
import { DOCUMENT_NOT_FOUND_ERROR } from "@api"

import Header from "./Header"
import CampaignPreviewModal from "./CampaignPreviewModal"
import RecipientReportsTable from "../RecipientReportsTable"
import CampaignContentUpdateForm from "./CampaignContentUpdateForm"
import CampaignSettingsUpdateForm from "./CampaignSettingsUpdateForm"
import { KEY_CONTENT, KEY_SETTINGS, KEY_REPORT } from "./CampaignSettingsUpdateForm/helpers/keys"

const PUBLISHED_STATUS = "PUBLISHED"
const ERROR_MESSAGE_CAMPAIGN_NOT_FOUND = "Campaign not found"


const CampaignUpdatePage = () => {
  const campaignPreviewModal = useRef({})

  const contentForm = useForm()
  const settingsForm = useForm()

  const canUpdateCampaign = hasAccess(['campaigns-write'])

  const navigate = useNavigate()
  const { request, showErrorMessage } = useAppContext()
  const { campaignId } = useParams()
  const {
    campaigns,
    indexCampaigns,
    updateCampaign,
    uploadCampaignAssets
} = useOutletContext()

  const [ tabId, setTabId ] = useState(KEY_CONTENT)
  const [ campaign, setCampaign ] = useState()
  const [ isSaving, setIsSaving ] = useState(false)
  const [ savingTabId, setSavingTabId ] = useState()

  const updateTabId = ({ status }) => {
    const isPublished = status === PUBLISHED_STATUS

    if (isPublished) {
      setTabId(KEY_REPORT)
    }
  }

  useEffect(() => {
    if (campaign) {
      return
    }

    return runRequest(() =>
      request(readOperation, { id: campaignId }, [ DOCUMENT_NOT_FOUND_ERROR ])
        .then(({ data }) => {
          updateTabId(data)
          setCampaign(data)
        })
        .then(() => {
          if (campaigns) {
            return
          }

          return indexCampaigns()
        })
        .catch(() => {
          showErrorMessage(ERROR_MESSAGE_CAMPAIGN_NOT_FOUND)
          navigate("/backstage/campaigns")
        })
    )
  }, [
    request,
    campaign,
    navigate,
    campaigns,
    campaignId,
    indexCampaigns,
    showErrorMessage
  ])

  const { name, status } = campaign || {}

  const close = () =>
    navigate('/backstage/campaigns')

  const save = () => {
    setIsSaving(true)
    contentForm.submit()
    settingsForm.submit()
  }

  const onSuccess = updatedCampaign => {
    updateCampaign(updatedCampaign)
    setIsSaving(false)
    close()
  }

  const onActionSuccess = (updatedCampaign, shouldClose = true) => {
    setCampaign(updatedCampaign)
    updateCampaign(updatedCampaign)

    if (!shouldClose) {
      return
    }

    close()
  }

  const uploadAssets = async (...props) => {
    const [ newAssets, updatedCampaign ] = await uploadCampaignAssets(campaign, ...props)

    const { assets } = updatedCampaign
    contentForm.setFieldValue('assets', assets)

    return [ newAssets, assets ]
  }

  const saveCampaign = async () => {
    const isPublished = status === "PUBLISHED"

    if (isPublished) {
      return true
    }

    const currentTabId = savingTabId || tabId

    const isSettings = currentTabId === KEY_SETTINGS

    const form = isSettings
      ? settingsForm
      : contentForm

    const formItem = form.getFieldsValue()

    const hasFormError = await form.validateFields()
      .then(() => false)
      .catch(() => true)

    if (hasFormError) {
      return false
    }

    const mutation = optimizeMutation(formItem, campaign)
    const shouldSubmit = Object.keys(mutation).length > 0


    if (!shouldSubmit) {
      return true
    }

    if (!canUpdateCampaign) {
      return true
    }

    const { id } = campaign
    const { data } = await request(updateOperation, { id, mutation })

    setCampaign(data)
    updateCampaign(data)

    return true
  }

  const openPreviewModal = async () =>
    campaignPreviewModal.current.open()

  const onChange = async (newTabId) => {
    setSavingTabId(tabId)
    setTabId(newTabId)

    await saveCampaign()
    setSavingTabId()
  }

  const currentTabId = savingTabId || tabId

  const isSettings = currentTabId === KEY_SETTINGS

  const isContent = currentTabId === KEY_CONTENT

  const isReport = currentTabId === KEY_REPORT

  let style = {}

  if (isSettings) {
    style = {
      padding: W2,
      maxWidth: DRAWER_WIDTH,
      marginBottom: W5,
    }
  }

  const headerProps = {
    title: name,
    value: tabId,
    onSave: save,
    onCancel: close,
    status,
    onChange,
    isSaving,
    savingTabId,
  }

  return (
    <>
      <Header {...headerProps} />

      <Divider style={{ margin: 0 }} />

      <Row justify="center">
        <Col flex="auto" style={style}>
          {
            campaign && (
              <>
                {
                  isContent && (
                    <CampaignContentUpdateForm
                      item={campaign}
                      form={contentForm}
                      tabId={currentTabId}
                      onSuccess={onSuccess}
                      uploadAssets={uploadAssets}
                      saveCampaign={saveCampaign}
                    />
                  )
                }
                {
                  isSettings && (
                    <CampaignSettingsUpdateForm
                      item={campaign}
                      form={settingsForm}
                      tabId={currentTabId}
                      onSuccess={onSuccess}
                      uploadAssets={uploadAssets}
                      saveCampaign={saveCampaign}
                      onActionSuccess={onActionSuccess}
                      openPreviewModal={openPreviewModal}
                    />
                  )
                }

                {
                  isReport && (
                    <RecipientReportsTable
                      campaignId={campaign.id}
                    />
                  )
                }

                <CampaignPreviewModal
                  modal={campaignPreviewModal}
                  campaign={campaign}
                />
              </>
            )
          }
        </Col>
      </Row>
    </>
  )
}

export default CampaignUpdatePage
