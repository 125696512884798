import { useEffect, useState } from "react"

import { operationRequest } from "@components/Authorization"

import setTitle from "./setTitle"
import isInternalDomain from "./isInternalDomain"

const operation = "organizations/ReadPublicOrganization"


const usePublicOrganization = () => {
  const isInternal = isInternalDomain()
  const [ organization, setOrganization ] = useState({})

  useEffect(() => {
    if (isInternal) {
      return
    }

    operationRequest({ operation })
      .then(({ data }) => setOrganization(data))
      .catch(error => console.error)
  }, [ isInternal ])

  useEffect(() => {
    const { name } = organization

    if (!name) {
      return
    }

    setTitle(name)
  }, [ organization ])

  return organization
}

export default usePublicOrganization
