import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Card, Spin } from "antd"
import { useOutletContext } from "react-router-dom"

import { isMobile } from "@components"
import { runRequest } from "@components/AppContext"

import Profile from "./Profile"

import "../AccountCard/AccountCard.css"

const LABEL_TITLE = "Profiles"


const BankAccountsCard = ({
  cardProps: otherCardProps = {},
}) => {
  const [ isLoading, setIsLoading ] = useState(false)

  const {
    profiles,
    indexProfiles,
    indexInvestments,
    getSignedInvestmentIds
  } = useOutletContext()

  useEffect(() => {
    return runRequest(() => {
      setIsLoading(true)
      indexInvestments()
        .then(() => indexProfiles())
        .then(() => setIsLoading(false))
    })
  }, [ indexProfiles, indexInvestments ])

  const cardProps = {
    type: "inner",
    size: isMobile ? "small": "medium",
    title: LABEL_TITLE,
    ...otherCardProps
  }

  return (
    <Spin spinning={isLoading}>
      <Card {...cardProps}>
        {
          profiles
            .filter(profile => getSignedInvestmentIds(profile.investmentIds).length > 0)
            .map((profile, key) => (
              <Card.Grid
                key={key}
                size={isMobile ? "small": "medium"}
                style={{
                  width: "100%",
                  padding: isMobile ? 12 : 24,
                  paddingBottom: isMobile ? 6 : 12,
                }}
                hoverable={false}
              >
                <Profile profile={profile} />
              </Card.Grid>
            ))
        }
      </Card>
    </Spin>
  )
}

BankAccountsCard.propTypes = {
  cardProps: PropTypes.shape(),
}

export default BankAccountsCard
