import brnv from "bank-routing-number-validator"

import { TYPE_HIDDEN, TYPE_RADIO, TYPE_STRING } from "@components/Form"

const ERROR_NUMBER_MISMATCH = "Should match account number"
const ERROR_INVALID_ROUTING_NUMBER = "Invalid routing number"
const ERROR_INVALID_ACCOUNT_NUMBER = "Invalid account number"

const LABEL_CONFIRM = "Confirm"
const LABEL_ACCOUNT_NUMBER = "Account Number"


const getInputs = (form, customerId, header) => {
  const headerInput = header
    ? [{
      name: "_header",
      component: () => header
    }]
    : []


  const inputs = [
    ...headerInput,
    {
      name: "customerId",
      type: TYPE_HIDDEN,
      initialValue: customerId
    },
    {
      name: "shouldRequestVerification",
      type: TYPE_HIDDEN,
    },
    {
      name: "bankName",
    },
    {
      name: "accountType",
      type: TYPE_RADIO,
    },
    {
      name: "routingNumber",
      placeholder: "",
      minLength: 9,
      maxLength: 9,
      itemProps: {
        rules: [{
          message: ERROR_INVALID_ROUTING_NUMBER,
          validator: async (_, value) => {
            const isValid = brnv.ABARoutingNumberIsValid(value)

            if (!isValid) {
              throw new Error(ERROR_INVALID_ROUTING_NUMBER)
            }
          }
        }]
      },
      inputProps: {
        style: { width: 180 },
      },
    },
    {
      name: "accountNumber",
      minLength: 4,
      maxLength: 17,
      placeholder: "",
      inputProps: {
        style: { width: 180 },
      },
      itemProps: {
        rules: [{
          message: ERROR_INVALID_ACCOUNT_NUMBER,
          pattern: /^[0-9]{4,17}$/,
        }]
      },
    },
    {
      name: "_confirmAccountNumber",
      type: TYPE_STRING,
      label: `${LABEL_CONFIRM} ${LABEL_ACCOUNT_NUMBER}`,
      placeholder: "",
      minLength: 4,
      maxLength: 17,
      inputProps: {
        style: { width: 180 },
      },
      itemProps: {
        rules: [
          {
            message: ERROR_NUMBER_MISMATCH,
            validator: async () => {
              const { accountNumber, _confirmAccountNumber } = form.getFieldsValue()

              if (!accountNumber) {
                return
              }

              const isValid = accountNumber === _confirmAccountNumber

              if (!isValid) {
                throw new Error(ERROR_NUMBER_MISMATCH)
              }
            }
          }
        ],
      },
    },
  ]

  return inputs
}

export default getInputs
