import React, { useMemo } from "react"
import { Tag, Typography } from "antd"

import { Value } from "@components/Text"
import { UserGroupsColumn } from "@components/Column"

import OrganizationStatusTag from "../OrganizationStatusTag"

const LABEL_ID = "ID"
const LABEL_NAME = "Name"
const LABEL_GROUPS = "Groups"
const LABEL_STATUS = "Status"


const useTableColumns = (openOrganizationDrawer) => {
  return useMemo(() => {
    const idColumnProps = {
      key: "id",
      title: LABEL_ID,
      render: value => (
        <Tag bordered={false}>
          {value}
        </Tag>
      ),
      isSearchable: true
    }

    const nameColumnProps = {
      key: "name",
      title: LABEL_NAME,
      render: (value, organization) => (
        <Typography.Link
          strong
          onClick={() => openOrganizationDrawer(organization)}
        >
          {value}
        </Typography.Link>
      ),
      isSearchable: true
    }

    const userGroupsColumnProps = {
      key: "userGroupIds",
      title: LABEL_GROUPS,
      render: (_, { userGroupIds = [] }) =>
        <UserGroupsColumn userGroupIds={userGroupIds}/>
    }

    const computeStatus = ({ isDisabled, isDeleted }) => {
      if (isDisabled) {
        return "DISABLED"
      }

      if (isDeleted) {
        return "DELETED"
      }

      return "ACTIVE"
    }

    const statusColumnProps = {
      key: "isDisabled",
      title: LABEL_STATUS,
      isSortingEnabled: true,
      compute: computeStatus,
      render: (_, organization) => (
        <OrganizationStatusTag
          organization={organization}
        />
      ),
    }

    const createdAtColumnProps = {
      key: "createdAt",
      render: value =>
        <Value value={value} />
    }

    return [
      { ...idColumnProps, width: 110 },
      { ...nameColumnProps },
      { ...userGroupsColumnProps },
      { ...statusColumnProps, width: 110 },
      { ...createdAtColumnProps, width: 170 },
    ]

  }, [
    openOrganizationDrawer,
  ])
}

export default useTableColumns
