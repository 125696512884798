import packageJson from '../package.json'

export const STAGING_HOSTS = [
  "demo.fundamentalportal.com",
  "stg.fundflow.slatestudio.com",
]

export const PRODUCTION_DOMAINS = [
  "vortexinvest.co",
  "fundamentalportal.com",
  "prd.fundflow.slatestudio.com",
]

export const CONFIG = {
  dev: {
    hasFooter: true,
    hasDocusign: false,
    hasCampaigns: true,
    is2FaRequired: true,
    isAchOptionEnabled: true,
    isAccreditationFlowEnabled: true,
    consoleOrganizationId: "OOK",
    isCustomDwollaTemplatesEnabled: true,
    ENVIRONMENT_LABEL: "dev",
    PLATFORM_DOMAIN: "fundflow.slatestudio.com",
    IMGIX_DOMAIN: "fundflow-storage-public-dev.imgix.net",
    S3_BUCKET_URL: "https://fundflow-storage-public-dev.s3.us-east-1.amazonaws.com/",
    CUSTOMER_EMAIL_DOMAIN: "inbound-dev.fundflow.slatestudio.com",
    AUTHENTICATOR_CONFIG: {
      storage: window.localStorage,
      aws_user_pools_id: "us-east-1_7OILtrdZi",
      aws_cognito_region: "us-east-1",
      aws_user_pools_web_client_id: "7k983mectopgjqu64ttlrpgd0p",
    },
    SENTRY_ENVIRONMENT: "development",
    SENTRY_INVESTOR_DNS: "https://da02bc444170f0c91fbc590f64c23696@o4505997396475904.ingest.sentry.io/4506314410688512",
    SENTRY_BACKSTAGE_DNS: "https://b4c70c2e82787e9362cadbd41c5a69ac@o4505997396475904.ingest.sentry.io/4506314409377792",
    PLATFORM_NAME: "Fundflow Portal",
    PLATFORM_EMAIL: "support@fundflow.slatestudio.com",
    PLATFORM_WEBSITE: "fundflow.slatestudio.com",
    PLATFORM_SHORT_NAME: "Fundflow",
    PLATFORM_VERSION: packageJson.version,
    IMPERSONATION_ENABLED_ORG_IDS: ["ODL", "O6E"],
  },
  stg: {
    hasFooter: true,
    hasDocusign: false,
    hasCampaigns: false,
    is2FaRequired: true,
    isAchOptionEnabled: true,
    consoleOrganizationId: "O49",
    isAccreditationFlowEnabled: false,
    isCustomDwollaTemplatesEnabled: false,
    ENVIRONMENT_LABEL: "demo",
    PLATFORM_DOMAIN: "fundamentalportal.com",
    IMGIX_DOMAIN: "fundflow-storage-public-dev.imgix.net",
    S3_BUCKET_URL: "https://fundflow-storage-public-dev.s3.us-east-1.amazonaws.com/",
    CUSTOMER_EMAIL_DOMAIN: "inbound-stg.fundflow.slatestudio.com",
    AUTHENTICATOR_CONFIG: {
      storage: window.localStorage,
      aws_user_pools_id: "us-east-1_HvVHICzPe",
      aws_cognito_region: "us-east-1",
      aws_user_pools_web_client_id: "7pe65rvnihdddvp5mjeeoqhge5",
    },
    SENTRY_ENVIRONMENT: "staging",
    SENTRY_INVESTOR_DNS: "https://da02bc444170f0c91fbc590f64c23696@o4505997396475904.ingest.sentry.io/4506314410688512",
    SENTRY_BACKSTAGE_DNS: "https://b4c70c2e82787e9362cadbd41c5a69ac@o4505997396475904.ingest.sentry.io/4506314409377792",
    PLATFORM_NAME: "Fundamental Portal",
    PLATFORM_EMAIL: "support@fundamentalportal.com",
    PLATFORM_WEBSITE: "fundamentalportal.com",
    PLATFORM_SHORT_NAME: "Fundamental",
    PLATFORM_VERSION: packageJson.version,
    IMPERSONATION_ENABLED_ORG_IDS: ["O6E"],
  },
  prd: {
    hasFooter: true,
    hasDocusign: true,
    hasCampaigns: false,
    is2FaRequired: true,
    isAchOptionEnabled: false,
    consoleOrganizationId: "O49",
    isAccreditationFlowEnabled: false,
    isCustomDwollaTemplatesEnabled: false,
    ENVIRONMENT_LABEL: "",
    PLATFORM_DOMAIN: "fundamentalportal.com",
    IMGIX_DOMAIN: "fundflow-storage-public-prd.imgix.net",
    S3_BUCKET_URL: "https://fundflow-storage-public-prd.s3.us-east-1.amazonaws.com/",
    CUSTOMER_EMAIL_DOMAIN: "inbound-prd.fundflow.slatestudio.com",
    AUTHENTICATOR_CONFIG: {
      storage: window.localStorage,
      aws_user_pools_id: "us-east-1_rwXtMOaQp",
      aws_cognito_region: "us-east-1",
      aws_user_pools_web_client_id: "2268dok4kkushvg65gcrfv9blu",
    },
    SENTRY_ENVIRONMENT: "production",
    SENTRY_INVESTOR_DNS: "https://da02bc444170f0c91fbc590f64c23696@o4505997396475904.ingest.sentry.io/4506314410688512",
    SENTRY_BACKSTAGE_DNS: "https://b4c70c2e82787e9362cadbd41c5a69ac@o4505997396475904.ingest.sentry.io/4506314409377792",
    PLATFORM_NAME: "Fundamental Portal",
    PLATFORM_EMAIL: "support@fundamentalportal.com",
    PLATFORM_WEBSITE: "fundamentalportal.com",
    PLATFORM_SHORT_NAME: "Fundamental",
    PLATFORM_VERSION: packageJson.version,
    IMPERSONATION_ENABLED_ORG_IDS: ["ONM", "O8E"],
  }
}
