import React from "react"
import PropTypes from "prop-types"
import { Row, Col, Typography, Steps, Space, Tag, Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

import { W0, W1, HEADER_HEIGHT } from "@components"
import { hasAccess } from "@components/Authorization"

import { KEY_CONTENT, KEY_SETTINGS, KEY_REPORT } from "./CampaignSettingsUpdateForm/helpers/keys"

const LABEL_SAVE = "Save & Close"
const LABEL_CLOSE = "Close"
const LABEL_SAVING = "Saving..."
const LABEL_REPORT = "Recipient Report"
const LABEL_LOADING = "Loading..."
const LABEL_CONTENT = "Create Campaign"
const LABEL_SETTINGS = "Review & Publish"

const STATUS_PUBLISHED = "PUBLISHED"


const Header = ({
  title = LABEL_LOADING,
  value,
  status = undefined,
  onSave,
  onCancel,
  onChange,
  isSaving,
  savingTabId = undefined,
}) => {
  const canUpdateCampaigns = hasAccess(['campaigns-write'])

  const isPublished = status === STATUS_PUBLISHED

  const items = [
    {
      title: LABEL_CONTENT,
    },
    {
      title: LABEL_SETTINGS,
    }
  ]

  if (isPublished) {
    items.push({
      title: LABEL_REPORT,
    })
  }

  const keys = [ KEY_CONTENT, KEY_SETTINGS, KEY_REPORT ]

  if (savingTabId) {
    const index = keys.findIndex(key => key === savingTabId)

    items[index].icon = <LoadingOutlined />
    items[index].title = LABEL_SAVING
    items[index].className = "ignore-inverted-colors"
  }

  const current = keys.findIndex(key => key === value)

  const stepOnChange = index =>
    onChange(keys[index])

  const buttonWidth = isPublished
    ? 40
    : 90

  const cancelButton = (
    <Typography.Link strong onClick={onCancel}>
      {LABEL_CLOSE}
    </Typography.Link>
  )

  let button = (
    <Typography.Link strong onClick={onSave}>
      {LABEL_SAVE}
    </Typography.Link>
  )

  if (isPublished || !canUpdateCampaigns) {
    button = cancelButton
  }

  return (
    <Row
      align="middle"
      style={{
        height: HEADER_HEIGHT,
        paddingLeft: W1,
        paddingRight: W1,
      }}
    >
      <Col>
        <div style={{ position: "absolute", left: 0, top: -1 * 12 }}>
          <Space style={{ whiteSpace: "nowrap" }}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {title}
            </Typography.Title>

            {
              status && (
                <Tag bordered={false}>{status}</Tag>
              )
            }
          </Space>
        </div>
      </Col>

      <Col flex="auto" align="center">
        <Steps
          type="navigation"
          size="small"
          items={items}
          style={{ width: isPublished ? 540 : 360 }}
          current={current}
          onChange={stepOnChange}
          className="site-navigation-steps steps-inverted-colors"
        />
      </Col>

      <Col align="right">
        <div style={{
          top: -1.4 * W0,
          right: 0,
          width: buttonWidth,
          position: "absolute",
        }}>
          <Spin spinning={isSaving}>
            {button}
          </Spin>
        </div>
      </Col>
    </Row>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string.isRequired,
  status: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  savingTabId: PropTypes.string,
}

export default Header
