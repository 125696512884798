import { isLocal } from "@components"
import { getConfig } from "@components/Config"

const ENVIRONMENT_LABEL = getConfig("ENVIRONMENT_LABEL")


const setTitle = (title, isImpersonated) => {
  let prefix = ""
  let postfix = ""

  if (ENVIRONMENT_LABEL) {
    prefix = isLocal
      ? `(local) `
      : `(${ENVIRONMENT_LABEL}) `
  }

  if (isImpersonated) {
    postfix = " / Impersonated"
  }

  document.title = `${prefix}${title}${postfix}`
}

export default setTitle
