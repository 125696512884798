import { Auth as Cognito } from "aws-amplify"

import { wait } from "@api"

import Storage from "./Storage"

const REFRESH_DELAY_MS = 5000


class Auth {
  static signOut() {
    Storage.reset()

    return Cognito.signOut()
  }

  static getIdToken = async () => {
    let user = {}

    try {
      user = await Cognito.currentAuthenticatedUser()

    } catch (error) {
      const isUserNotAuthenticated = error === 'The user is not authenticated'

      if (isUserNotAuthenticated) {
        Storage.reset()

        window.location.reload()

        // NOTE: Assuming that page context is killed and failed pending request
        //       would not send exception to Sentry.
        await wait(REFRESH_DELAY_MS)
      }

      throw error
    }

    const { signInUserSession } = user
    const { jwtToken } = signInUserSession.idToken

    return jwtToken
  }

  static async refreshAuthentication(authenticate, isBackstage) {
    Storage.removeBackstageAuthentication()

    await Cognito.currentAuthenticatedUser({ bypassCache: true })

    const authenticationJwt = await authenticate(() => {}, isBackstage, true)

    return authenticationJwt
  }
}

export default Auth
