import React from "react"
import { Tag, Tooltip } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"

import { IndexOrganizationsOutputShape } from "@api/services/organizations"


const OrganizationStatusTag = ({
  organization
}) => {
  const {
    isDisabled,
    isDestroyed,
    disableReason,
  } = organization

  const params = {
    color: "green",
    title: "Active"
  }

  if (isDisabled) {
    params.color = "orange"
    params.title = "Disabled"
  }

  if (isDestroyed) {
    params.color = "red"
    params.title = "Destroyed"
  }

  const hasDisableReason =
    isDisabled &&
    !!disableReason &&
    disableReason !== ""

  return (
    <>
      <Tag
        color={params.color}
        bordered={false}
      >
        {params.title}
      </Tag>
      {
        hasDisableReason && (
          <Tooltip
            title={disableReason}
            placement="top"
          >
            <ExclamationCircleOutlined />
          </Tooltip>
        )
      }
    </>
  )
}

OrganizationStatusTag.propTypes = {
  organization: IndexOrganizationsOutputShape.isRequired,
}

export default OrganizationStatusTag
