import React, { useState } from "react"
import PropTypes from "prop-types"
import { Typography, Row, Col, Space, Switch } from "antd"
import { MobileOutlined, DesktopOutlined } from "@ant-design/icons"

import { Modal } from "@components/Page"
import { hasAccess } from "@components/Authorization"
import unwrapEmail from "@components/Form/Input/RedactorInput/helpers/unwrapEmail"
import { Bold, EmailPreview } from "@components/Text"
import { W0, W1, W2, DRAWER_WIDTH2 } from "@components"

import SendTestEmailButton from "./SendTestEmailButton"

const LABEL_FROM = "From"
const LABEL_TITLE = "Campaign Preview"
const LABEL_SUBJECT = "Subject"
const LABEL_REPLY_TO = "Reply To"
const LABEL_UNDEFINED = "Undefined"
const LABEL_SEND_DISABLED = "(send test email function is disabled)"


const CampaignPreviewModal = ({ modal, campaign }) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isMobile, setIsMobile ] = useState(false)

  const canUpdateCampaigns = hasAccess(['campaigns-write'])

  const {
    html,
    subject,
    fromName,
    fromAddress,
    replyToAddress
  } = campaign

  const renderFromAddress = () => fromName
    ? (
        <>
          <Bold>
            {fromName}
          </Bold>
          {` <${fromAddress}>`}
        </>
      )
    : (
        <Space>
          <Typography.Text strong>{LABEL_UNDEFINED}</Typography.Text>
          <Typography.Text type="secondary">{LABEL_SEND_DISABLED}</Typography.Text>
        </Space>
      )

  modal.current.open = () =>
    setIsOpen(true)

  const onClose = () =>
    setIsOpen(false)

  const modalProps = {
    width: DRAWER_WIDTH2,
    title: LABEL_TITLE,
    maskClosable: true,
    isOpen,
    onClose,
  }

  const emailHtml = unwrapEmail(html)

  const shouldShowReplyTo = replyToAddress !== fromAddress

  return (
    <Modal {...modalProps}>
      <Row>
        <Col span={24}>
          <Row>
            <Col flex="auto">
              <Row>
                <Col span={24}>
                  <Space>
                    <Typography.Text type="secondary">{LABEL_FROM}:</Typography.Text>
                    <Typography.Text>{renderFromAddress()}</Typography.Text>
                  </Space>
                </Col>

                <Col span={24}>
                  <Space>
                    <Typography.Text type="secondary">{LABEL_SUBJECT}:</Typography.Text>
                    <Typography.Text>{subject}</Typography.Text>
                  </Space>
                </Col>

                {
                  shouldShowReplyTo && (
                    <Col span={24}>
                      <Space>
                        <Typography.Text type="secondary">{LABEL_REPLY_TO}:</Typography.Text>
                        <Typography.Text>{replyToAddress}</Typography.Text>
                      </Space>
                    </Col>
                  )
                }
              </Row>
            </Col>
            <Col style={{ paddingTop: W0 * 1.5, paddingRight: W0  }}>
              <Switch
                style={{ float: "right" }}
                value={isMobile}
                onChange={setIsMobile}
                checkedChildren={<MobileOutlined />}
                unCheckedChildren={<DesktopOutlined />}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ marginTop: W1 }}>
          <EmailPreview
            html={emailHtml}
            isMobile={isMobile}
          />
        </Col>

        {
          canUpdateCampaigns && (
            <Col span={24} style={{ marginTop: W2, marginBottom: W1, }}>
              <SendTestEmailButton campaign={campaign} />
            </Col>
          )
        }
      </Row>
    </Modal>
  )
}

CampaignPreviewModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  campaign: PropTypes.shape().isRequired,
}

export default CampaignPreviewModal
