import React from "react"
import { Space, Typography } from "antd"
import { green, blue } from "@ant-design/colors"
import { CheckCircleOutlined, HourglassOutlined } from "@ant-design/icons"

import { ORGANIZATION_DOMAIN_STATUS } from "@components/Domain"
import { IndexOrganizationsOutputShape } from "@api/services/organizations"

const { Link } = Typography


const OrganizationDomain = ({ organization }) => {
  const {
    domain,
    domainStatus
  } = organization

  const isConnected = domainStatus === ORGANIZATION_DOMAIN_STATUS.COMPLETE

  const domainStatusIcon = isConnected
    ? <CheckCircleOutlined style={{ color: green.primary }} />
    : <HourglassOutlined style={{ color: blue.primary }} />

  return (
    <div style={{ position: "relative" }}>
      <Space>
        <Link href={`https://${domain}/backstage/`} target="_blank">
          {domain}
        </Link>

        { !!domainStatus && domainStatusIcon }
      </Space>
    </div>
  )
}

OrganizationDomain.propTypes = {
  organization: IndexOrganizationsOutputShape.isRequired,
}

export default OrganizationDomain
