
const PRD_PREFIX = 'prd.'
const DEV_PREFIX ='dev.'

const isInternalDomain = () => {
  const isInternal =
    window.location.hostname.startsWith(PRD_PREFIX) ||
    window.location.hostname.startsWith(DEV_PREFIX)

  return isInternal
}

export default isInternalDomain

