import React from "react"
import PropTypes from "prop-types"
import { Steps, Space, Typography } from "antd"

import { IndexOrganizationsOutputShape } from "@api/services/organizations"

import config from "./config"


const OrganizationDestroySteps = ({
  organization = undefined,
  current,
}) => {
  const getStatus = index => {
    const { isDestroyed } = organization

    if (isDestroyed) {
      return "finish"
    }

    if (index < current) {
      return "finish"
    }

    if (index === current) {
      return "process"
    }

    return "wait"
  }

  const items = config
    .map((configItem, index) => {
      let { description } = configItem
      const { title, timestampFieldName } = configItem

      const status = getStatus(index)
      const destroyedAt = (organization || {})[timestampFieldName]

      if (destroyedAt) {
        description += `, destroyed at ${new Date(destroyedAt).toLocaleString()}`
      }

      description = (
        <Typography.Text type="secondary">
          {description}
        </Typography.Text>
      )

      return {
        title,
        status,
        description,
      }
    })

  const stepsProps = {
    size: "small",
    direction: "vertical",
    items,
    current,
  }

  return (
    <Space direction="vertical">
      <Steps {...stepsProps} />
    </Space>
  )
}

OrganizationDestroySteps.propTypes = {
  current: PropTypes.number.isRequired,
  organization: IndexOrganizationsOutputShape,
}

export default OrganizationDestroySteps
