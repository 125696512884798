import React from "react"
import { Descriptions, Typography } from "antd"

import { JsonMarkdown } from "@components/Text"
import { CopyValueButton } from "@components/Button"
import { IndexOrganizationsOutputShape } from "@api/services/organizations"
import { getConfig } from "@components/Config"

import OrganizationEmail from "./OrganizationEmail"
import OrganizationDomain from "./OrganizationDomain"
import OrganizationStatusTag from "../OrganizationsTable/OrganizationStatusTag"

const ENVIRONMENT_LABEL = getConfig('ENVIRONMENT_LABEL')
const EMAIL_DASHBOARD_URL =
  "https://us-east-1.console.aws.amazon.com/ses/home?region=us-east-1#/vdm/dashboard/configuration-set"

const LABEL_ID = "ID"
const LABEL_NAME = "Name"
const LABEL_EMAIL = "Email"
const LABEL_STATUS = "Status"
const LABEL_DOMAIN = "Domain"
const LABEL_SOURCE = "Source"
const LABEL_NOTIFICATIONS = "Notifications"
const LABEL_MARKETING_LINK = "View Marketing Dashboard"
const LABEL_TRANSACTIONAL_LINK = "View Transactional Dashboard"


const OrganizationDescriptions = ({ organization }) => {
  const { id } = organization

  const marketingDashboardUrl = `${EMAIL_DASHBOARD_URL}/${ENVIRONMENT_LABEL}-marketing-${id}`
  const transactionalDashboardUrl = `${EMAIL_DASHBOARD_URL}/${ENVIRONMENT_LABEL}-transactional-${id}`

  const items = [
    {
      label: LABEL_NAME,
      value: (
        <Typography.Text strong>
          {organization.name}
        </Typography.Text>
      ),
    },
    {
      label: LABEL_ID,
      value: <CopyValueButton value={organization.id} />,
    },
    {
      label: LABEL_STATUS,
      value: <OrganizationStatusTag organization={organization} />
    },
    {
      label: LABEL_DOMAIN,
      value: <OrganizationDomain organization={organization} />,
    },
    {
      label: LABEL_EMAIL,
      value: <OrganizationEmail organization={organization} />,
    },
    {
      label: LABEL_NOTIFICATIONS,
      value: (
        <>
          <Typography.Link
            href={transactionalDashboardUrl}
            target="_blank"
          >
            {LABEL_TRANSACTIONAL_LINK}
          </Typography.Link>
          <br/>
          <Typography.Link
            href={marketingDashboardUrl}
            target="_blank"
          >
            {LABEL_MARKETING_LINK}
          </Typography.Link>
        </>
      )
    },
    {
      label: LABEL_SOURCE,
      value: <JsonMarkdown json={JSON.stringify(organization, null, 2)} />,
    },
  ]

  const descriptionsProps = {
    size: "small",
    column: 1,
    bordered: true,
    className: "compact black",
    labelStyle: { width: "30%" },
  }

  return (
    <Descriptions {...descriptionsProps}>
      {
        items.map(({ label, value }, key) =>
          <Descriptions.Item
            key={key}
            label={label}
          >
            {value}
          </Descriptions.Item>
        )
      }
    </Descriptions>
  )
}

OrganizationDescriptions.propTypes = {
  organization: IndexOrganizationsOutputShape.isRequired,
}

export default OrganizationDescriptions

// import { useOutletContext } from "react-router-dom"
// import Identity from "@components/Identity"
// import TableRowActions from "../TableRowActions"
// import OrganizationEmail from "../OrganizationEmail"
// import OrganizationDomain from "../OrganizationDomain"

// const { users, computeUserFullname } = useOutletContext()

// const getUserId = userCorrelationId => {
//   const user = users
//     .find(({ correlationId }) => correlationId === userCorrelationId)

//   if (!user) {
//     return null
//   }

//   return user.id
// }

// const computeFullname = userCorrelationId => {
//   const userId = getUserId(userCorrelationId)

//   return computeUserFullname(userId)
// }


// const createdByColumnProps = {
//   key: "createdBy",
//   compute: ({ createdBy }) => computeFullname(createdBy),
//   render: (fullname, { createdBy }) => (
//     getUserId(createdBy)
//       ? <Identity id={getUserId(createdBy)}>{fullname}</Identity>
//       : "N/A"
//   ),
//   isSearchable: true,
// }
