import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Space } from "antd"
import { useOutletContext } from "react-router-dom"

import { wait } from "@api/helpers/wait"
import { Modal } from "@components/Page"
import { MarkdownAlert } from "@components/Text"
import { useAppContext } from "@components/AppContext"
import {
  indexOrganizationsOperation,
  IndexOrganizationsOutputShape
} from "@api/services/organizations"

import config from "./config"
import OrganizationDestroySteps from "./OrganizationDestroySteps"

const LABEL_TITLE = "Destroy Organization"
const LABEL_WARNING = `The destroy operation is irreversible. All
organization-related resources, including all investor data, will be deleted
from the platform. This process takes some time, so please do not close the
window.`


const OrganizationDestroyModal = ({
  organization,
  modal,
}) => {
  const { updateOrganization } = useOutletContext()
  const { authenticatedRequest } = useAppContext()

  const [ isOpen, setIsOpen ] = useState(false)
  const [ currentStep, setCurrentStep ] = useState(-1)
  const [ isInProgress, setIsInProgress ] = useState(false)

  const destroy = async () => {
    const { id } = organization

    setIsInProgress(true)

    for (let i = 0; i < config.length; i++) {
      const { operation = indexOrganizationsOperation } = config[i]

      setCurrentStep((prev) => prev + 1)

      try {
        await authenticatedRequest(operation, { id })
          .then(({ data: organizations }) => {
            const updatedOrganization = organizations
              .find(item => item.id === id)

            updateOrganization(updatedOrganization)
          })

        await wait(1000)


      } catch (error) {
        setIsInProgress(false)

        // td: Show error message
        break

      }
    }

    setIsInProgress(false)
  }

  modal.current.open = () =>
    setIsOpen(true)

  const onClose = () =>
    setIsOpen(false)

  const modalProps = {
    title: LABEL_TITLE,
    isOpen,
    onClose,
  }

  return (
    <Modal {...modalProps}>
      <Space
        style={{ width: "100%" }}
        direction="vertical"
      >
        <MarkdownAlert
          text={LABEL_WARNING}
          type="warning"
        />

        <OrganizationDestroySteps
          current={currentStep}
          organization={organization}
        />

        <Button
          danger
          style={{ width: "100%" }}
          loading={isInProgress}
          onClick={destroy}
        >
          {LABEL_TITLE}
        </Button>
      </Space>
    </Modal>
  )
}

OrganizationDestroyModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  organization: IndexOrganizationsOutputShape.isRequired,
}

export default OrganizationDestroyModal
