import { useEffect, useState, useCallback } from "react"
import { useOutletContext } from "react-router-dom"

import { runRequest, useAppContext } from "@components/AppContext"
import { DOCUMENT_NOT_FOUND_ERROR } from "@api"
import {
  readAccountOperation,
  readInvestmentOperation as readOperation,
} from "@api/services/investments"
import { useTemplatesStore } from "@components/Templates"

import updateProfileNames from "./helpers/updateProfileNames"
import { processInvestments } from "../../InvestmentForm/InvestmentForm"


const useInvestmentStore = (onInvestmentNotFound) => {
  const { request } = useAppContext()
  const {
    projectTemplatesStore: outletProjectTemplatesStore
  } = useOutletContext()

  const [ account, setAccount ] = useState()
  const [ investment, setInvestment ] = useState()

  const { projectId } = investment || {}

  const templatesStore = useTemplatesStore(projectId)
  const { indexTemplates } = templatesStore

  const indexProjectTemplates = useCallback(() => {
    if (outletProjectTemplatesStore) {
      return
    }

    return indexTemplates()
  }, [
    indexTemplates,
    outletProjectTemplatesStore,
  ])

  const isLoading = !investment || !account

  const readInvestment = useCallback(id => {
    request(readOperation, { id }, [ DOCUMENT_NOT_FOUND_ERROR ])
      .then(({ data }) => {
        const [ processedInvestment ] = processInvestments([ data ])
        updateProfileNames(processedInvestment)

        setInvestment(processedInvestment)

        const { investorAccountId } = data
        return investorAccountId
      })
      .then(investorAccountId => request(readAccountOperation, { id: investorAccountId }))
      .then(({ data }) => setAccount(data))
      .catch(() => onInvestmentNotFound(id))
  }, [ request, onInvestmentNotFound ])

  useEffect(() => {
    if (!projectId) {
      return
    }

    return runRequest(() => {
      indexProjectTemplates()
    })
  }, [
    projectId,
    indexProjectTemplates
  ])

  const updateInvestment = newInvestment =>
    setInvestment({ ...investment, ...newInvestment })

  const projectTemplatesStore = outletProjectTemplatesStore
    ? outletProjectTemplatesStore
    : templatesStore

  return {
    account,
    isLoading,
    investment,
    setInvestment,
    readInvestment,
    updateInvestment,
    projectTemplatesStore
  }
}

export default useInvestmentStore
