import React from "react"
import PropTypes from "prop-types"
import { Space, Typography } from "antd"

import { Value } from "@components/Text"
import { WIDTHS } from "@components"

import ProjectShape from "../shapes/ProjectShape"
import ProjectEditableGroup from "./ProjectEditableGroup"

const { W0 } = WIDTHS
const { Text } = Typography

const LABEL_TITLE = "Summary"
const LABEL_CREATION_DATE = "Fund Creation Date"
const LABEL_PROPERTY_COUNT = "Number of Properties Invested Into"
const LABEL_EQUITY_DEPLOYED = "Total Equity Deployed"
const LABEL_OFFERING_STRUCTURE = "Offering Structure"


const ProjectFundSummary = ({ project, isEditable }) => {
  const { amount, targetCloseDate, fundOfferingStructure, investmentSubjects = [] } = project
  const propertiesCount = investmentSubjects.length

  const items = [
    {
      label: LABEL_CREATION_DATE,
      value: targetCloseDate
        ? <Value value={targetCloseDate} />
        : "—",
    },
    {
      label: LABEL_EQUITY_DEPLOYED,
      value: <Value value={amount} />,
    },
    {
      label: LABEL_PROPERTY_COUNT,
      value: propertiesCount,
    },
    {
      label: LABEL_OFFERING_STRUCTURE,
      value: fundOfferingStructure
        ? <Value value={fundOfferingStructure} />
        : "—",
    },
  ].map(({ label, value }, key) =>
    <Space key={key}>
      {`${label} `}
      <Text strong>
        {value}
      </Text>
    </Space>
  )

  const fieldNames = [
    'amount',
    'targetCloseDate',
    'fundOfferingStructure'
  ]

  return (
    <ProjectEditableGroup
      title={LABEL_TITLE}
      fieldNames={fieldNames}
      extraProps={{isEditable}}
    >
      <Space
        size={W0 / 2}
        direction="vertical"
      >
        {items}
      </Space>
    </ProjectEditableGroup>
  )
}

ProjectFundSummary.propTypes = {
  project: ProjectShape.isRequired,
  isEditable: PropTypes.bool.isRequired
}

export default ProjectFundSummary
