import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { getConfig } from "@components/Config"
import { VirtualTable } from "@components/Table"
import { useAppContext } from "@components/AppContext"
import { indexOrganizationsOperation as indexOperation } from "@api/services/organizations"

import useTableColumns from "./helpers/useTableColumns"
import useHeaderActions from "./helpers/useHeaderActions"

const consoleOrganizationId = getConfig("consoleOrganizationId")


const OrganizationsTable = ({
  table,
  openCreateModal,
  openOrganizationDrawer
}) => {
  const {
    authenticatedRequest,
  } = useAppContext()

  const {
    organizations: items,
    indexOrganizations: indexItems
  } = useOutletContext()

  const columnsConfig = useTableColumns(openOrganizationDrawer)

  const size = "small"
  const className = "last-column-action"
  const headerActions = useHeaderActions(openCreateModal)
  const defaultSortColumn = "name"

  const filterItemsCallback = useCallback(
    organizations => organizations.filter(({ id }) => id !== consoleOrganizationId )
  , [])

  const tableProps = {
    request: authenticatedRequest,
    size,
    table,
    items,
    className,
    indexItems,
    headerActions,
    columnsConfig,
    indexOperation,
    defaultSortColumn,
    filterItemsCallback,
  }

  return (
    <VirtualTable {...tableProps} />
  )
}

OrganizationsTable.propTypes = {
  table: PropTypes.shape().isRequired,
  openCreateModal: PropTypes.func.isRequired,
  openOrganizationDrawer: PropTypes.func.isRequired,
}

export default OrganizationsTable
