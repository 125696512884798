import React from "react"
import { NavLink } from "react-router-dom"
import { StopOutlined, UndoOutlined, DeleteOutlined } from "@ant-design/icons"

import useActions from './useActions'

const LABEL_ENABLE = "Enable"
const LABEL_DISABLE = "Disable"
const LABEL_DESTROY = "Destroy"


const useItems = (organization, table, { openDestroyModal, setOrganization }) => {
  const { enableOrganization, disableOrganization } = useActions(organization, table, setOrganization)

  if (!organization) {
    return
  }

  const { isDisabled, isDestroyed } = organization

  const items = []

  if (isDestroyed) {
    return items
  }

  if (isDisabled) {
    items.push({
      key: "enable",
      icon: <UndoOutlined />,
      label: (
        <NavLink onClick={() => enableOrganization()}>
          {LABEL_ENABLE}
        </NavLink>
      )
    })

    items.push(
      {
        type: "divider"
      }
    )

    items.push({
      key: "delete",
      icon: <DeleteOutlined />,
      danger: true,
      label: (
        <NavLink onClick={() => openDestroyModal()}>
          {LABEL_DESTROY}
        </NavLink>
      )
    })

    return items
  }

  items.push({
    key: "disable",
    icon: <StopOutlined />,
    danger: true,
    label: (
      <NavLink onClick={() => disableOrganization()}>
        {LABEL_DISABLE}
      </NavLink>
    )
  })

  return items
}

export default useItems
