
const getCustomerSlug = (name) => {
  if (!name) {
    return name
  }

  const slug = name
    .replace(/[^\w\s]|_/g, '') // Remove special characters
    .replace(/\s+/g, ' ') // Replace multiple spaces with single space
    .trim() // Remove spaces around string
    .toLowerCase() // Put name to lower case
    .replace(/\s/g, '-') // Replace spaces with dash symbol

  return slug
}

export default getCustomerSlug
