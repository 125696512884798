import React from "react"
import { Space } from "antd"
import PropTypes from "prop-types"

import { ReadMyOrganizationOutputShape } from "@api/services/organizations"
import { DropdownButton } from "@components/Button"

import useItems from "./helpers/useItems"


const Title = ({
  table,
  organization,
  setOrganization,
  openDestroyModal,
}) => {
  const items = useItems(
    organization,
    table,
    { openDestroyModal, setOrganization }
  )

  if (!items) {
    return
  }

  const organizationName = organization?.name

  const hasDropdown = items.length > 0

  if (!hasDropdown) {
    return organizationName
  }

  return (
    <Space>
      {
        hasDropdown && (
          <DropdownButton
            name={organizationName}
            items={items}
          />
        )
      }

    </Space>
  )
}

Title.propTypes = {
  table: PropTypes.shape().isRequired,
  organization: ReadMyOrganizationOutputShape.isRequired,
  setOrganization: PropTypes.func.isRequired,
  openDestroyModal: PropTypes.func.isRequired,
}

export default Title
