import { getConfig } from "@components/Config"
import { indexOrganizationsOperation } from "@api/services/organizations"

const PLATFORM_SHORT_NAME = getConfig("PLATFORM_SHORT_NAME")


const config = [
  {
    title: "Pinpoint Projects",
    operation: indexOrganizationsOperation,
    description: "All marketing campaigns",
    timestampFieldName: "pinpointProjectsDestroyedAt",
    // operation: "DestroyOrganizationPinpointProjects"
  },
  {
    title: "SES Identities",
    operation: indexOrganizationsOperation,
    description: "Custom domain to send campaigns from",
    timestampFieldName: "identitiesDestroyedAt",
    // operation: "DestroyOrganizationIdentities"
  },
  {
    title: "API Gateway",
    operation: indexOrganizationsOperation,
    description: `${PLATFORM_SHORT_NAME} subdomain configuration`,
    timestampFieldName: "gatewayDestroyedAt",
    // operation: "DestroyOrganizationGateway"
  },
  {
    title: "CloudFront Distributions",
    operation: indexOrganizationsOperation,
    description: "Workaround for HTTP to HTTPS redirect",
    timestampFieldName: "cloudfrontDistributionsDestroyedAt",
    // operation: "DestroyOrganizationCloudFrontDistribution"
  },
  {
    title: "Route53 Records",
    operation: indexOrganizationsOperation,
    description: `${PLATFORM_SHORT_NAME} subdomain DNS records`,
    timestampFieldName: "dnsRecordsDestroyedAt",
    // operation: "DestroyOrganizationDnsRecords"
  },
  {
    title: "SSL Certificates",
    operation: indexOrganizationsOperation,
    description: `${PLATFORM_SHORT_NAME} subdomain certificates`,
    timestampFieldName: "certificatesDestroyedAt",
    // operation: "DestroyOrganizationCertificates"
  },
  {
    title: "Cognito Users",
    operation: indexOrganizationsOperation,
    description: "Single organization access users",
    timestampFieldName: "cognitoUsersDestroyedAt",
    // operation: "DestroyOrganizationCognitoUsers"
  },
  {
    title: "Dwolla Customers",
    operation: indexOrganizationsOperation,
    description: "Suspend Dwolla customers for single organization access users",
    timestampFieldName: "dwollaCustomersDestroyedAt",
    // operation: "DestroyOrganizationDwollaCustomers"
  },
  {
    title: "S3 Files",
    operation: indexOrganizationsOperation,
    description: "All private and public files",
    timestampFieldName: "filesDestroyedAt",
    // operation: "DestroyOrganizationFiles"
  },
  // td: data is missing.
]

export default config
