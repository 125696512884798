import { useOutletContext } from "react-router-dom"
import dayjs from "dayjs"

import { useAppContext } from "@components/AppContext"
import { getFormattedAmountValue } from "@components/Amount"
import { INVESTOR_STATUS_LABELS_MAP } from "@components/Domain"

const LABEL_TRUE = "True"
const LABEL_FALSE = "False"
const LABEL_NEVER = "Never"


const useExportColumns = () => {
  const { isBroker } = useAppContext()
  const { getSponsorName, computeUserFullname } = useOutletContext()

  const columns = [
    {
      title: "Full Name",
      value: item => [
        item.investor.givenName,
        item.investor.additionalName,
        item.investor.familyName
      ].filter(value => !!value).join(" ")
    },
    {
      title: "Email",
      value: ({ investor }) => investor.email
    },
    {
      title: "Last Logged In",
      value: ({ lastAuthenticatedAt }) => lastAuthenticatedAt
        ? dayjs(lastAuthenticatedAt).format('MM/DD/YYYY HH:mm:ss')
        : LABEL_NEVER
    },
    {
      title: "Last Invested Date",
      value: ({ lastFundedAt }) => lastFundedAt
        ? dayjs(lastFundedAt).format('MM/DD/YYYY HH:mm:ss')
        : LABEL_NEVER
    },
    {
      title: "Shadow",
      value: ({ correlationId }) => !correlationId
        ? LABEL_TRUE
        : LABEL_FALSE
    },
  ]

  if (!isBroker) {
    columns.push({
      title: "Sponsor",
      value: ({ sponsorId }) => getSponsorName(sponsorId),
    })
    columns.push({
      title: "Liaison",
      value: ({ brokerUserId }) => computeUserFullname(brokerUserId),
    })
  }

  columns.push({
      title: "Status",
      value:  ({ status }) => INVESTOR_STATUS_LABELS_MAP[status],
    },
    {
      title: "Invested",
      value: ({ totalInvestedAmount }) => getFormattedAmountValue(totalInvestedAmount)
    }
  )

  return columns
}

export default useExportColumns
