import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { useForm } from "@components/Form"
import { MODAL_WIDTH } from "@components"
import { UpdateDrawer } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import { updateOrganizationOperation as updateOperation }
  from "@api/services/organizations"

import getInputs from "./helpers/getInputs"

const LABEL_ENTITY = "Organization"
const LABEL_TITLE = `Edit Organization`
const LABEL_SUBMIT = "Save"


const OrganizationUpdateDrawer = ({
  drawer,
  onSuccess
}) => {
  const { authenticatedRequest } = useAppContext()
  const { userGroups = [] } = useOutletContext()

  const form = useForm()

  const inputs = getInputs(userGroups)
  const schema = updateOperation.getSchema(inputs)

  const updateModalProps = {
    title: LABEL_TITLE,
    width: MODAL_WIDTH,
    entity: LABEL_ENTITY,
    request: authenticatedRequest,
    operation: updateOperation,
    submitLabel: LABEL_SUBMIT,
    form,
    drawer,
    schema,
    onSuccess
  }

  return (
    <UpdateDrawer {...updateModalProps} />
  )
}

OrganizationUpdateDrawer.propTypes = {
  drawer: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default OrganizationUpdateDrawer
