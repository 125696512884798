import { useState, useEffect, useCallback } from "react"
import { useOutletContext } from "react-router-dom"
import get from "lodash.get"

import { useAppContext } from "@components/AppContext"
import {
  JOINT,
  SDIRA,
  ENTITY,
  INDIVIDUAL
} from "@api/services/investments/shapes/ProfileTypeEnum"
import { getCustomerSlug } from "@components/Dwolla"

import extendBlockedInputs from "./extendBlockedInputs"
import getInvestmentSchema from "./getInvestmentSchema"

const LABEL_DISTRIBUTION_WARNING = `**WARNING:** Investment [FIELD] change
resets connected distributions account for this investment.`


const INDIVIDUAL_TYPES = [
  JOINT,
  INDIVIDUAL
]


const getProfileTypeExtraWarning = (isCreate, investment, formInvestment) => {
  if (isCreate) {
    return null
  }

  const { profileType, receiverSourceId } = investment
  const { profileType: formProfileType } = formInvestment

  if (!receiverSourceId) {
    return null
  }

  const hasProfileTypeChanged = profileType !== formProfileType

  if (!hasProfileTypeChanged) {
    return null
  }

  const shouldNotRenderWarning =
    INDIVIDUAL_TYPES.includes(profileType) &&
    INDIVIDUAL_TYPES.includes(formProfileType)

  if (shouldNotRenderWarning) {
    return null
  }

  return LABEL_DISTRIBUTION_WARNING.replace("[FIELD]", "profile type")
}

const getProfileNameExtraWarning = (isCreate, investment, formInvestment) => {
  if (isCreate) {
    return null
  }

  const { receiverSourceId } = investment
  const { profileType: formProfileType } = formInvestment

  if (!receiverSourceId) {
    return null
  }

  const shouldSkipValidation = ![
    SDIRA,
    ENTITY
  ].includes(formProfileType)

  if (shouldSkipValidation) {
    return null
  }

  const isSdira = formProfileType === SDIRA
  const isEntity = formProfileType === ENTITY

  if (isSdira) {
    const prevAccountName = get(investment, "profileSdira.accountName")
    const newAccountName = get(formInvestment, "profileSdira.accountName")
    const isAccountNameChanged = getCustomerSlug(prevAccountName) !== getCustomerSlug(newAccountName)

    if (!isAccountNameChanged) {
      return null
    }

    return LABEL_DISTRIBUTION_WARNING.replace("[FIELD]", "account name")
  }

  if (isEntity) {
    const prevEntityName = get(investment, "profileEntity.name")
    const newEntityName = get(formInvestment, "profileEntity.name")
    const isEntityNameChanged = getCustomerSlug(prevEntityName) !== getCustomerSlug(newEntityName)

    if (!isEntityNameChanged) {
      return null
    }

    return LABEL_DISTRIBUTION_WARNING.replace("[FIELD]", "entity name")
  }

  return null
}

const useUpdateSchema = ({
  form,
  tiers,
  setBlock,
  investment,
  profilesMap,
  organization,
  customIdentity,
  otherInvestments,
  isTypeSelectDisabled,
}) => {
  const [ schema, setSchema ] = useState([])

  const { identity } = useAppContext()

  const { getInvestorSsnNumber } = useOutletContext()

  const [ firstInvestment ] = (otherInvestments || [])

  const sponsorId = customIdentity?.sponsorId || firstInvestment?.sponsorId
  const targetIdentity = customIdentity || identity
  const investorAccountId = customIdentity?.id

  const updateSchema = useCallback(
    (blockMessage, blockingFieldName) => {
      let formInvestment = form.getFieldValue([])

      const isUpdate = !!investment
      const isCreate = !isUpdate

      if (isCreate) {
        const { investor } = targetIdentity
        formInvestment = { ...formInvestment, investor }
      }

      const profileTypeExtraWarning = getProfileTypeExtraWarning(isCreate, investment, formInvestment)
      const profileNameExtraWarning = getProfileNameExtraWarning(isCreate, investment, formInvestment)

      const newSchema = getInvestmentSchema({
        investment: formInvestment,
        tiers,
        sponsorId,
        profilesMap,
        organization,
        updateSchema,
        otherInvestments,
        investorAccountId,
        getInvestorSsnNumber,
        isTypeSelectDisabled,
        profileTypeExtraWarning,
        profileNameExtraWarning
      })

      const blockForm = () => {
        if (!setBlock) {
          return
        }

        if (!blockMessage) {
          setBlock()
        }

        setBlock(blockMessage)
        extendBlockedInputs(newSchema, formInvestment, blockingFieldName)
      }

      blockForm()
      setSchema(newSchema)
    }
  , [
    form,
    tiers,
    setBlock,
    sponsorId,
    investment,
    profilesMap,
    organization,
    targetIdentity,
    otherInvestments,
    investorAccountId,
    isTypeSelectDisabled,
    getInvestorSsnNumber,
  ])

  useEffect(() => {
    updateSchema()
  }, [ updateSchema ])

  return schema
}

export default useUpdateSchema
