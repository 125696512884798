import React from "react"

import {
  TOOLTIP_PROFILE_DRE,
  ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_IRA,
  ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_LLC,
  BENEFICIAL_OWNER_TAX_FILING_STATUS_TITLES_MAP,
  ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_INDIVIDUAL,
  ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_TITLES_MAP,
} from "@components/Domain"
import { MarkdownAlert, Disclaimer } from "@components/Text"
import { W1 } from "@components"
import { TYPE_EIN, TYPE_RADIO, TYPE_STRING } from "@components/Form"
import { getUsAddressSchema, getAddressSchema } from "@components/Address"

import RULES from "./rules"
import computeResidencyInputs from "./computeResidencyInputs"
import getProfileEntityInputsMap from "./getProfileEntityInputsMap"

const LABEL_BENEFICIAL_OWNER_NAME = "Beneficial Owner Name"
const TOOLTIP_DRE_BENEFICIAL_OWNER_NAME = `The beneficial owner of a disregarded
entity is the person (or entity), as shown on its income tax return, where the
disregarded entity's income or loss is reported. For example, it is the single
member of a single member LLC, or the grantor of a grantor trust. If the direct
owner of the entity is also a disregarded entity, please list the first owner
that is not disregarded for federal tax purposes. Please consult your tax
advisor if you are unsure.`


const getProfileEntityTrusteeSchema = (organization, updateSchema, profileNameExtraWarning, profile = {}) => {
  const { email: organizationEmail } = organization

  const {
    hasEinNumber,
    beneficialOwnerAddress,
    beneficialOwnerEntityType,
    isDisregardedForTaxPurposes,
    hasSsn,
    isUsPerson,
    isUsCitizen
  } = profile

  const inputPropsMap = getProfileEntityInputsMap()

  const isIndividual = beneficialOwnerEntityType === ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_INDIVIDUAL
  const isLLC = isDisregardedForTaxPurposes && beneficialOwnerEntityType === ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_LLC
  const isIra = isDisregardedForTaxPurposes && beneficialOwnerEntityType === ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_IRA
  const isSetBeneficialType = beneficialOwnerEntityType !== undefined
  const isNotDisregarded = isDisregardedForTaxPurposes === false
  const isNotUsPerson = isUsPerson === false

  const commonInputs = [
    {
      name: "name",
      type: TYPE_STRING,
      label: "Trust’s Full Legal Name",
      required: true,
      maxLength: 255,
      itemProps: {
        extra: profileNameExtraWarning
          ? (
            <MarkdownAlert
              text={profileNameExtraWarning}
              type="warning"
              style={{ marginTop: W1, marginBottom: 0 }}
            />
          )
          : null,
        rules: [
          RULES.ENTITY_NAME
        ]
      },
      inputProps: {
        onChange: () => updateSchema()
      },
    },
    {
      name: "isDisregardedForTaxPurposes",
      label: "Is this trust disregarded for federal income tax purposes?",
      required: true,
      itemProps: {
        extra: <Disclaimer text={TOOLTIP_PROFILE_DRE} />
      },
      inputProps: {
        onChange: () => updateSchema()
      },
    }
  ]

  let inputs = [
    ...commonInputs,
    ...[
      inputPropsMap.hasEinNumber,
      inputPropsMap.beneficialOwnerEntityType,
      inputPropsMap.beneficialOwnerName,
      inputPropsMap.einNumber,
      inputPropsMap.beneficialOwnerAddress,
      inputPropsMap.isUsCitizen,
      inputPropsMap.residenceState,
      inputPropsMap.hasOtherUsResidenceState,
      inputPropsMap.beneficialOwnerSsnNumber,
      inputPropsMap.beneficialOwnerEinNumber,
      inputPropsMap.isUsPerson,
      inputPropsMap.address,
      inputPropsMap.hasSsn,
      inputPropsMap.hasEinNumber,
      inputPropsMap.beneficialOwnerCustodianEinNumber,
      inputPropsMap.beneficialOwnerTaxFilingStatus,
    ]
  ]

  const isNotUsCitizen = isUsCitizen === false
  const isTin = isNotUsPerson && isNotUsCitizen

  const getHasSsnLabel = () => {
    if (isIndividual) {
      if (isTin) {
        return "Does this beneficial owner have a US TIN?"
      }

      return "Does this beneficial owner have a SSN?"
    }

    if (isIra) {
      return "Does this beneficial owner custodian have an EIN?"
    }

    return "Does this beneficial owner have an EIN?"
  }

  let labels = {
    hasOtherUsResidenceState: "Is the state listed under the address also the beneficial owner's state of residence for tax purposes?",
    residenceState: "Please list the beneficial owner's state of residence",
    isUsPerson: "Is this beneficial owner considered a US person for federal tax purposes, as defined by the IRS?",
    hasSsn: getHasSsnLabel()
  }

  if (isDisregardedForTaxPurposes) {
    if (isSetBeneficialType && !isIndividual) {
      profile.isUsCitizen = false
    }

    const einNumberInput = hasEinNumber
      ? {
        name: "einNumber",
        type: TYPE_EIN,
        label:  "Trust EIN",
        required: true,
      }
      : inputPropsMap.einNumber

    const iraInputs = isIra && hasSsn
      ? {
        name: "beneficialOwnerCustodianEinNumber",
        type: TYPE_EIN,
        label: "Beneficial Owner Custodian EIN",
      }
      : inputPropsMap.beneficialOwnerCustodianEinNumber

    const llcInputs = isLLC
      ? [
        {
          name: "beneficialOwnerTaxFilingStatus",
          type: TYPE_RADIO,
          label: "Tax Filing Status",
          required: true,
          options: Object.keys(BENEFICIAL_OWNER_TAX_FILING_STATUS_TITLES_MAP)
            .map(value => ({ value, label: BENEFICIAL_OWNER_TAX_FILING_STATUS_TITLES_MAP[value] })),
        }
      ]
      : [
        inputPropsMap.beneficialOwnerTaxFilingStatus,
      ]

    const addressInputs = isSetBeneficialType
      ? {
        name: "beneficialOwnerAddress",
        label: "",
        schema: getAddressSchema(updateSchema, ["profileEntity", "beneficialOwnerAddress"], beneficialOwnerAddress, {}, {
          addressCountry: "Beneficial Owner Country",
          streetAddress: "Beneficial Owner Address",
          addressLocality: "Beneficial Owner City",
          usAddressRegion: "Beneficial Owner State",
          addressRegion: "Beneficial Owner Region",
          usPostalCode: "Beneficial Owner ZIP Code",
          postalCode: "Beneficial Owner Postal Code",
        }),
      }
      : inputPropsMap.beneficialOwnerAddress

    const isUsCitizenInput = isIndividual
      ? {
        name: "isUsCitizen",
        label: "Is this beneficial owner a US citizen?",
        required: true,
        inputProps: {
          onChange: () => updateSchema()
        }
      }
      : inputPropsMap.isUsCitizen

    const residencyInputs = computeResidencyInputs(profile, updateSchema, labels, organizationEmail)

    const stateInputs = isSetBeneficialType
      ? [
        ...residencyInputs,
      ]
      : [
        inputPropsMap.residenceState,
        inputPropsMap.hasOtherUsResidenceState,
        inputPropsMap.isUsPerson,
        inputPropsMap.hasSsn
      ]

    const shouldShowSnnInput = isIndividual && hasSsn

    const shouldShowEinInput = isSetBeneficialType && !isIndividual && hasSsn && !isIra

    const einInput = shouldShowEinInput
      ? {
        name: "beneficialOwnerEinNumber",
        type: TYPE_EIN,
        required: true,
        label: "Beneficial Owner EIN",
      }
      : inputPropsMap.beneficialOwnerEinNumber

    const ssnInput = shouldShowSnnInput
      ? {
        name: "beneficialOwnerSsnNumber",
        required: true,
        label: isTin ? "Beneficial Owner ITIN" : "Beneficial Owner SSN",
      }
      : inputPropsMap.beneficialOwnerSsnNumber

    inputs = [
      ...commonInputs,
      ...[
        inputPropsMap.address,
        {
          name: "hasEinNumber",
          label: "Does this trust have an EIN?",
          required: true,
          inputProps: {
            onChange: () => updateSchema()
          },
        },
        einNumberInput,
        {
          name: "beneficialOwnerEntityType",
          type: TYPE_RADIO,
          label: "Beneficial Owner Entity Type",
          required: true,
          options: Object.keys(ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_TITLES_MAP)
            .map(value => ({ value, label: ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_TITLES_MAP[value] })),
          inputProps: {
            onChange: () => updateSchema()
          },
        },
        {
          name: "beneficialOwnerName",
          label: LABEL_BENEFICIAL_OWNER_NAME,
          type: TYPE_STRING,
          itemProps: {
            extra: <Disclaimer text={TOOLTIP_DRE_BENEFICIAL_OWNER_NAME} />
          },
          required: true,
        },
        ...llcInputs,
        addressInputs,
        isUsCitizenInput,
      ],
      ...stateInputs,
      ...[
        einInput,
        ssnInput,
        iraInputs
      ],
    ]
  }

  if (isNotDisregarded) {
    labels = {
      hasOtherUsResidenceState: "Is the state listed under the address also the trust state of residence for tax purposes?",
      residenceState: "Please list the trust state of residence",
      isUsPerson: "Is this trust considered a US person for federal tax purposes, as defined by the IRS?",
      hasEinNumber: "Does this trust have an EIN?"
    }

    profile.isUsCitizen = false

    const residencyInputs = computeResidencyInputs(profile, updateSchema, labels, organizationEmail, true)

    const einNumberInput = hasEinNumber
      ? {
        name: "einNumber",
        type: TYPE_EIN,
        label: "Trust EIN",
        required: true,
      }
      : inputPropsMap.einNumber

    const stateInputs = [
      ...residencyInputs,
      inputPropsMap.hasSsn
    ]

    inputs = [
      ...commonInputs,
      inputPropsMap.beneficialOwnerTaxFilingStatus,
      ...[
        {
          name: "address",
          label: "",
          schema: getUsAddressSchema(beneficialOwnerAddress),
          required: true,
        }
      ],
      ...stateInputs,
      {
        name: "hasEinNumber",
        label: "Does this trust have an EIN?",
        required: true,
        inputProps: {
          onChange: () => updateSchema()
        },
      },
      ...[einNumberInput],
      ...[
        inputPropsMap.beneficialOwnerEntityType,
        inputPropsMap.beneficialOwnerName,
        inputPropsMap.beneficialOwnerAddress,
        inputPropsMap.isUsCitizen,
        inputPropsMap.beneficialOwnerTaxFilingStatus,
        inputPropsMap.beneficialOwnerSsnNumber,
        inputPropsMap.beneficialOwnerEinNumber,
        inputPropsMap.beneficialOwnerCustodianEinNumber,
      ]
    ]
  }

  return inputs
}

export default getProfileEntityTrusteeSchema
