import React from "react"
import { Space, Typography } from "antd"
import { green, blue } from "@ant-design/colors"
import { CheckCircleOutlined, HourglassOutlined } from "@ant-design/icons"

import { IndexOrganizationsOutputShape } from "@api/services/organizations"

const { Text } = Typography


const OrganizationEmail = ({ organization }) => {
  const { email, isEmailVerified } = organization

  const emailStatusIcon = isEmailVerified
    ? <CheckCircleOutlined style={{ color: green.primary }} />
    : <HourglassOutlined style={{ color: blue.primary }} />

  const isEmailVerifiedSet = isEmailVerified !== undefined

  return (
    <Space>
      <Text>{email}</Text>

      { isEmailVerifiedSet && emailStatusIcon }
    </Space>
  )
}

OrganizationEmail.propTypes = {
  organization: IndexOrganizationsOutputShape.isRequired,
}

export default OrganizationEmail
