import React from "react"
import { PlusOutlined } from "@ant-design/icons"
import { useAppContext } from "@components/AppContext"

const LABEL_CREATE = "Add Organization"


const useHeaderActions = (openCreateModal) => {
  const { isRoot } = useAppContext()

  if (!isRoot) {
    return []
  }

  return [
    {
      icon: <PlusOutlined />,
      title: LABEL_CREATE,
      action: openCreateModal,
    },
  ]
}

export default useHeaderActions
